import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { useNavigate } from "react-router-dom";
import style from "../assets/styles/Account.module.css";

const Edit = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    name: "",
    email: "",
    type: "",
    status: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const { token, users, socket } = useSelector(
    useMemo(
      () => (store) => ({
        token: store.token,
        users: store.users,
        socket: store.socket,
      }),
      []
    )
  );
  const formattedDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const handleChange = (e) => {
    setValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      let res = await fetch(`${process.env.REACT_APP_API}/auth/${id}`, {
        method: "PATCH",
        body: JSON.stringify({ ...values, token }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      let data = await res.json();
      console.log(data);
      if (data?.error) {
        toast.error(`${data.error}`);
        setIsLoading(false);
        return;
      }
      socket.emit("database-update");
      toast.success(`User ${values.name} edited Successfully`);
      setValues({ name: "", email: "", type: "",status:"" });
      setTimeout(() => {
        navigate("/");
      }, 2000);
    } catch (error) {
      console.log("EDIT ERROR", error);
      toast.error(`Something went wrong`);
    }
    setIsLoading(false);
  };
  const today = new Date().toISOString().split("T")[0];
  useEffect(() => {
    const user = users.find((user) => user._id === id);
    if (user) {
      user.data = formattedDate(user.data);
      setValues(user);
    }
  }, []);
  return (
    <div className={style["create-user-wrapper"]}>
      <ToastContainer />
      <div className={style.header}>
        <h1>Edit User</h1>
      </div>
      <form onSubmit={handleEdit} className={style["form-container"]}>
        <div className={style["form-group"]}>
          <label htmlFor="name" className={style["form-label"]}>
            User Name
          </label>
          <input
            type="text"
            id="name"
            className={style["form-input"]}
            placeholder="Enter user name"
            value={values.name}
            name="name"
            required
            onChange={handleChange}
            disabled={isLoading}
          />
        </div>

        <div className={style["form-group"]}>
          <label htmlFor="name" className={style["form-label"]}>
            Email
          </label>
          <input
            type="email"
            id="email"
            className={style["form-input"]}
            placeholder="Enter email"
            value={values.email}
            name="email"
            required
            onChange={handleChange}
            disabled={isLoading}
          />
        </div>

        <div className={style["form-group"]}>
          <label htmlFor="type" className={style["form-label"]}>
            Select the type
          </label>
          <select
            id="type"
            name="type"
            value={values.type}
            onChange={handleChange}
            className={style["form-input"]}
            // required
          >
            <option value={"customer"}>customer</option>
            <option value={"support"}>support</option>
            <option value={"admin"}>admin</option>
          </select>
        </div>


        <div className={style["form-group"]}>
          <label htmlFor="status" className={style["form-label"]}>
            Select the status
          </label>
          <select
            id="status"
            name="status"
            value={values.status}
            onChange={handleChange}
            className={style["form-input"]}
            // required
          >
            <option value={"active"}>active</option>
            <option value={"not_active"}>not active</option>
          </select>
        </div>
        
        <button
          disabled={isLoading}
          type="submit"
          className={style["form-button"]}
        >
          {isLoading ? "Wait..." : "Edit"}
        </button>
      </form>
    </div>
  );
};

export default Edit;
