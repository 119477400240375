import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { useNavigate } from "react-router-dom";
import style from "../assets/styles/Account.module.css";

const CreateUser = () => {
  const [values, setValues] = useState({
    name: "",
    email: "",
    password: "",
    type : "support",
  });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { token, socket } = useSelector((store) => ({
    token: store.token,
    socket: store.socket,
  }));

  const handleUser = async () => {
    setIsLoading(true);
    try {
      let res = await fetch(`${process.env.REACT_APP_API}/auth`, {
        method: "POST",
        body: JSON.stringify({ ...values, token }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      let data = await res.json();
      console.log(data);
      if (data?.error) {
        toast.error(`${data.error}`);
        setIsLoading(false);
        return;
      }
      setValues({ name: "", email: "", password: "" ,type :""});
      socket.emit("database-update");
      setTimeout(() => {
        navigate("/");
      }, 3000);
      toast.success(`User ${values.name} added Successfully`);
    } catch (error) {
      console.log("ACCOUNT ERROR", error);
      toast.error(`Something went wrong`);
    }
    setIsLoading(false);
  };
  const handleChange = (e) => {
    setValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  
  const handleSubmit = (e) => {
    
    const { name, email, password ,type} = values;

    if (!name || !email || !password ) {
      toast.error(`Please provide all valuess`);
      return;
    }

    if (password.length < 6) toast.error(`Password is too short`);

    e.preventDefault();
    handleUser();
  };
  const today = new Date().toISOString().split("T")[0];
  useEffect(() => {}, []);
  return (
    <div className={style["create-user-wrapper"]}>
      <ToastContainer />
      <div className={style.header}>
        <h1>Add New User</h1>
      </div>
      <form onSubmit={handleSubmit} className={style["form-container"]}>
        <div className={style["form-group"]}>
          <label htmlFor="name" className={style["form-label"]}>
           Name
          </label>
          <input
            type="text"
            id="name"
            className={style["form-input"]}
            placeholder="Enter name"
            value={values.name}
            name="name"
            required
            onChange={handleChange}
            disabled={isLoading}
          />
        </div>

        <div className={style["form-group"]}>
          <label htmlFor="name" className={style["form-label"]}>
          Email
          </label>

          <input
            type="email"
            id="email"
            className={style["form-input"]}
            placeholder="Enter email"
            value={values.email}
            name="email"
            required
            onChange={handleChange}
            disabled={isLoading}
          />
        </div>

        <div className={style["form-group"]}>
          <label htmlFor="password" className={style["form-label"]}>
            Password
          </label>
          <input
            name="password"
            type="password"
            id="password"
            className={style["form-input"]}
            onChange={handleChange}
            value={values.password}
            disabled={isLoading}
          />
        </div>

        <div className={style["form-group"]}>
          <label htmlFor="date" className={style["form-label"]}>
            Select the type
          </label>
          <select
            id="type"
            name="type"
            value={values.type}
            onChange={handleChange}
            className={style["form-input"]}
            // required
          >
            <option value={"support"}>support</option>
            <option value={"admin"}>admin</option>
          </select>
        </div>

        <button
          disabled={isLoading}
          type="submit"
          className={style["form-button"]}
        >
          {isLoading ? "Wait..." : "Add"}
        </button>
      </form>
    </div>
  );
};

export default CreateUser;
